import Vue from "vue"
import VueRouter from "vue-router"
import Home from "../views/Home.vue"

Vue.use(VueRouter)

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/equipos",
        name: "Equipments",
        component: () =>
            import ("../views/ComputerEquipment/ComputerEquipment.vue"),
    },
    {
        path: "/carga-completa",
        name: "cargaCompleta",
        component: () =>
            import ("../views/massive/EquipmentOk.vue"),
    },
    {
        path: "/agregar-equipo-computo",
        name: "AddEquipment",
        component: () =>
            import ("../views/ComputerEquipment/AddComputerEquipment.vue"),
    },
    {
        path: "/editar-equipo/:id",
        name: "ComputerEquipmentEdit",
        component: () =>
            import ("../views/ComputerEquipment/edit.vue"),
    },
    {
        path: "/antivirus",
        name: "Antivirus",
        component: () =>
            import ("../views/Antivirus/Antivirus.vue"),
    },
    {
        path: "/agregarAntivirus",
        name: "AddAntivirus",
        component: () =>
            import ("../views/Antivirus/AddAntivirus.vue"),
    },
    {
        path: "/editar-antivirus/:id",
        name: "EditarAntivirus",
        component: () =>
            import ("../views/Antivirus/EditAntivirus.vue"),
    },
    {
        path: "/asignar-equipo",
        name: "equipmentAssignament",
        component: () =>
            import ("../views/Assignaments/equipmentAssignament.vue"),
    },
    {
        path: "/asignaciones-equipo",
        name: "equipmentAssignamentList",
        component: () =>
            import ("../views/Assignaments/equipmentAssignamentList.vue"),
    },
    {
        path: "/editar-asignar/:id",
        name: "equipmentAssignamentEdit",
        component: () =>
            import ("../views/Assignaments/editEquipmentAssignament.vue"),
    },
    {
        path: "/tipodiscoduro",
        name: "HardDisks",
        component: () =>
            import ("../views/HardDisk/HardDisks.vue"),
    },
    {
        path: "/agregartipodiscoduro",
        name: "AddHD",
        component: () =>
            import ("../views/HardDisk/AddHD.vue"),
    },
    {
        path: "/editartipodiscoduro/:id",
        name: "EditHD",
        component: () =>
            import ("../views/HardDisk/EditHD.vue"),
    },
    {
        path: "/tiposequipo",
        name: "EquipmentType",
        component: () =>
            import ("../views/EquipmentType/EquipmentType.vue"),
    },
    {
        path: "/agregartipoequipo",
        name: "AddEquipmentType",
        component: () =>
            import ("../views/EquipmentType/AddEquipmentType.vue"),
    },
    {
        path: "/editartipoequipo/:id",
        name: "EditEquipmentType",
        component: () =>
            import ("../views/EquipmentType/EditEquipmentType.vue"),
    },
    {
        path: "/cargaequipos",
        name: "equipmentMassive",
        component: () =>
            import ("../views/massive/equipment.vue"),
    },
    {
        path: "/cargaequiposusuario",
        name: "equipmentuserMassive",
        component: () =>
            import ("../views/massive/equipmentuser.vue"),
    },
    {
        path: "/cargaanvirus",
        name: "cargaantivirus",
        component: () =>
            import ("../views/massive/antivirus.vue"),
    },
    {
        path: "/about",
        name: "About",
        component: () =>
            import ("../views/About.vue"),
    },
	{
        path: "/marcas",
        name: "Brands",
        component: () =>
            import ("../views/Brand/list.vue"),
    },
	{
        path: "/crear-marca",
        name: "BrandCreate",
        component: () =>
            import ("../views/Brand/add.vue"),
    },
	{
        path: "/editar-marca/:id",
        name: "BrandEdit",
        component: () =>
            import ("../views/Brand/edit.vue"),
    },
]

const router = new VueRouter({
    mode: "history",
    routes,
})

export default router