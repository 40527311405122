<template>
    <v-app>
        <v-app-bar app color="primary" dark>
            <div class="d-flex align-center">
                <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                <a href="/"><v-img alt="Vuetify Logo" class="shrink mr-2" contain src="/img/logo.png" transition="scale-transition" width="40" /></a>
                <h2><b>Consubanco</b></h2>
            </div>
            <v-spacer></v-spacer>
        </v-app-bar>
        
        <v-main>
			<v-container fluid>
				<v-row>
      				<v-col cols="2">

						<v-navigation-drawer v-model="drawer" absolute permanent>

							<v-list nav dense>
								<v-list-group :value="true">
									<template v-slot:activator>
										<v-list-item-title>Carga masiva de Información</v-list-item-title>
									</template>
							
									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/cargaequipos">Equipos de Computo</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/cargaequiposusuario">Asignar Equipo</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/cargaanvirus">Antivirus</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									
								</v-list-group>

								<v-list-group :value="true">
									<template v-slot:activator>
										<v-list-item-title>Ingreso Manual</v-list-item-title>
									</template>
							
									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/equipos">Equipos de Computo</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/asignaciones-equipo">Asignar Equipo</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									<v-list-group
										:value="true"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/antivirus">Antivirus</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>

									
								</v-list-group>

								<v-list-group :value="true">
									<template v-slot:activator>
										<v-list-item-title>Administración</v-list-item-title>
									</template>
<!--
									<v-list-group
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title><a href="/equipos">Roles</a></v-list-item-title>
											</v-list-item-content>
										</template>
									</v-list-group>
-->
									<v-list-group
										:value="false"
										no-action
										sub-group>
										<template v-slot:activator>
											<v-list-item-content>
												<v-list-item-title>Catalogos</v-list-item-title>
											</v-list-item-content>
										</template>

										<v-list-item link>
											<v-list-item-title><a href="/marcas">Marca de Equipos</a></v-list-item-title>
										</v-list-item>
										<v-list-item link>
											<v-list-item-title><a href="/tiposequipo">Tipo de Equipos</a></v-list-item-title>
										</v-list-item>
										<v-list-item link>
											<v-list-item-title><a href="/tipodiscoduro">Tipo de Disco Duro</a></v-list-item-title>
										</v-list-item>
									</v-list-group>
								</v-list-group>
							</v-list>
						</v-navigation-drawer>
					</v-col>

					<v-col cols="10">
						<router-view />
					</v-col>
				</v-row>
			</v-container>
        </v-main>
    </v-app>
</template>
<script>
    export default {
      name: "App",
      data: () => ({}),
      drawer: false,
      created() {},
    }
</script>