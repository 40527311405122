import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import i18n from "./plugins/i18n"
import axios from "axios"
import store from "./store"
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
// no se uso import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';


Vue.config.productionTip = false
Vue.use(require('vue-moment'))

window.axios = axios
new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
},VueMoment, {
  moment,
}).$mount("#app")
