<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="12">
                <img src="/img/logo.png" alt="consubanco" class="mx-auto" width="200" />
            </v-col>
            <v-col class="mb-5" cols="12"></v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
      name: "HelloWorld",
    
      data: () => ({
        
      }),
    }
</script>
<style>
    .sections-select{
    margin-top: 1em !important;
    }
</style>