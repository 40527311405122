import Vue from "vue";
import VueI18n from "vue-i18n";
import english from "./en";
import spanish from "./es";
Vue.use(VueI18n);
const messages = {
  es: spanish,
  en: english,
};
const i18n = new VueI18n({
  locale: "es",
  fallbackLocale: "en",
  messages,
});
export default i18n;
