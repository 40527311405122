export default {
  titles: {
    machine: "Agregar un nuevo equipo",
    antivirus: "Agregar un antivirus",
    computerEquipment: "Agregar equipo de computo",
    equipmentAssignament: "Asignación de un equipo a un empleado",
    assignedEquipments: "Equipos asignados a empleados",
    hardisk: "Tipos de disco duro",
    equipmentType: "Tipos de equipo",
    adquisitionDate: "Fecha de compra",
    finishDate: "Fecha fin de garantía",
    deadline: "Tiempo de garantía",
  },
  menu: {
    equipments: "Equipos",
    addEquipment: "Alta de Equipo",
    addAntivirus: "Alta de Antivirus",
    editAntivirus: "Editar Antivirus",
    antivirus: "Antivirus",
    addComputerEquipment: "Alta de Equipo de Computo",
    computerEquipment: "Equipo de Computo",
    equipmentAssignament: "Asignación de Equipo a un empleado",
    assignedEquipments: "Equipos asignados a empleados",
    editAssignedEquipment: "Editar equipo asignado a empleado",
    addHardDisk: "Alta de tipo de disco duro",
    editHardDisk: "Editar tipo de disco duro",
    addEquipmentType: "Alta de tipo de equipo",
    editEquipmentType: "Editar tipo de equipo",
    equipmentMassive: "Carga masiva de equipo",
    equipmentuserMassive: "Carga masiva de relación equipo-usuario",
    cargaantivirus:"Carga masiva de Antivirus",
  },
  serieNumber: "Número de serie",
  back: "Regresar",
  save: "Guardar",
  brand: "Marca",
  model: "Modelo",
  equipmentType: "Tipo de equipo",
  name: "Nombre",
  surname: "Primer Apellido",
  lastname: "Segundo Apellido",
  job: "Puesto",
  area: "Área",
  required: "Requerido",
  minrequired: "Mínimo 3 carácteres",
  status: "Estatus",
  validationDate: "Fecha de validación",
  description: "Descripción",
  selection: "Seleccionar archivo a cargar",
  equipmentlayout: "Descarga layout de carga de equipo",
  equipmentuserlayout: "Descarga layout de carga de equipo relación usuario",
  antivirusLayout: "Descarga layout de carga de antivirus",
  memory: "Memoria",
  hardisk: "Disco duro",
  cargaComplete: "Carga completa",
}
