export default {
  titles: {
    machine: "Add a new machine",
    antivirus: "Add an antivirus",
    computerEquipment: "Agregar equipo de computo",
    equipmentAssignament: "Equipment assignnament to employeer",
    assignedEquipments: "Assigned equipments to employeers",
    hardisk: "Type of hardisk",
    equipmentType: "Type of equipment",
    adquisitionDate: "Adquisition date",
    finishDate: "Finish date of guarantee",
    deadline: "Deadline of the guarantee",
  },
  menu: {
    equipments: "Equipments",
    addEquipment: "Add an equipment",
    addAntivirus: "Add an antivirus",
    editAntivirus: "Edit an Antivirus",
    antivirus: "Antivirus",
    addComputerEquipment: "Add a computer equipment",
    computerEquipment: "Computer equipment",
    equipmentAssignament: "Equipment assignament",
    assignedEquipments: "Assigned equipments",
    editAssignedEquipment: "Edit a equipment assigment by employee",
    addHardDisk: "Add a new type of hard disk",
    editEquipmentType: "Edit a equipment type",
    editHardDisk: "Edit an hard disk type",
    addEquipmentType: "Add a new type of equipment",
    equipmentMassive: "Massive equipment load",
    equipmentuserMassive: "Massive equipment-user load",
    cargaantivirus:"Massive Antivirus uplad",
  },
  serieNumber: "Serie number",
  back: "Back",
  save: "Save",
  brand: "Brand",
  model: "Model",
  equipmentType: "Type of equipment",
  name: "Name",
  surname: "Surname",
  lastname: "Lastname",
  job: "Job",
  area: "Area",
  required: "Required",
  minrequired: "Min 3 characters",
  status: "Status",
  validationDate: "Validation date",
  description: "Description",
  selection: "Selection of a file",
  equipmentlayout: "Download the equipment layout",
  equipmentuserlayout: "Download the equipment-user layout",
  antivirusLayout: "Download the antivirus layout",
  memory: "Memory",
  hardisk: "Hard disk",
  cargaComplete: "Load complete",
}
